<template>
  <loading v-if="$_.isEmpty(site) || $_.isEmpty(contract)" centered />

  <div v-else class="columns is-multiline is-marginless has-padding-50">
    <div class="column is-12">
      <div class="box is-paddingless">
        <screenshot
          :filename="site.screenshot || ''"
          :title="site.fqdn || 'loading'"
          classes="has-background-white"
        />
      </div>
    </div>

    <div class="column is-12">
      <collapse title="Staff controls">
        <contract-controls :contract-id="contractId" :site-id="siteId" />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Site details">
        <site-details :site="site" />
      </collapse>
    </div>

    <div class="column is-12">
      <user-details :user-id="contract.authorId" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminContractAside",
  components: {
    "site-details": () => import("@shared/sites/_siteDetails"),
    "user-details": () => import("@shared/sites/_userDetails"),
    "contract-controls": () => import("@shared/admin/_contractControls")
  },
  data() {
    return {
      contractId: this.$route.params.contractId
    };
  },
  computed: {
    contract() {
      return this.$store.getters["billing/contract"](this.contractId) || {};
    },
    siteId() {
      return this.$_.get(this.contract, "site.ref.id");
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    }
  },
  watch: {
    siteId(siteId, oldSiteId) {
      if (!oldSiteId && siteId) {
        this.observeSite();
      }
    }
  },
  created() {
    if (this.siteId) {
      this.observeSite();
    }
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  methods: {
    observeSite() {
      this.$store
        .dispatch("sites/observeSite", { siteId: this.siteId })
        .catch(() => {
          this.siteNotFound();
        });
    },
    siteNotFound() {
      this.$router.push(`/admin/sites`);
      this.$toast.open({
        message: `Invalid permissions or site not found`,
        position: "is-bottom",
        queue: false,
        type: "is-danger"
      });
    }
  }
};
</script>
